<template>
  <div class="layout">
    <van-tabbar route safe-area-inset-bottom :placeholder="true">
      <van-tabbar-item v-for="item in tabbarArray" :to="item.path" :key="item.name" active-color="var(--title-color)"
        inactive-color="var(--main-color)">
        {{$t(item.name)}}
        <template #icon="props">
          <img :src="props.active ? item.active : item.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>

import homePng from "@/assets/home.png";
import homeCheckedPng from "@/assets/home-checked.png";
import rankPng from "@/assets/rank.png";
import rankCheckedPng from "@/assets/rank-checked.png";
import typePng from "@/assets/type.png";
import typeCheckedPng from "@/assets/type-checked.png";
import userPng from "@/assets/user.png";
import userCheckedPng from "@/assets/user-checked.png";

export default {
  name: 'layout',
  components: {},
  data() {
    return {
      tabbarArray: [
        {
          name: 'home',
          icon: 'wap-home-o',
          inactive: homePng,
          active: homeCheckedPng,
          keepAlive: true,
          path: '/lnicio'
        },
        {
          name: 'streaming',
          icon: 'bar-chart-o',
          inactive: rankPng,
          active: rankCheckedPng,
          keepAlive: true,
          path: '/streaming'
        },
        {
          name: 'categories',
          icon: 'apps-o',
          inactive: typePng,
          active: typeCheckedPng,
          keepAlive: true,
          path: '/classificacao'
        },
        {
          name: 'user',
          icon: 'user-o',
          inactive: userPng,
          active: userCheckedPng,
          keepAlive: true,
          path: "/user"
        }
      ],
    }
  },
  // 计算属性
  computed: {},
  watch: {

  },
  mounted() {
    let domain = window.location.hostname
    console.log(domain)
    if (domain === 'pt.k5ca.com' || domain === 'pt.8vy4.com') {
      const style = document.createElement('style');
      style.textContent = `
      .van-tabbar-item{
        justify-content: flex-start;
      }
      .van-tabbar {
        height: 60px;
      }
    `;
      document.head.appendChild(style);
    }
    // this.getTabbar(this.$router.options.routes);
  },
  methods: {
    // getTabbar(routers) {
    //   routers.forEach((item) => {
    //     (item.name === 'Layout') && (this.tabbarArray = item.children)
    //   })
    // }
  }
}
</script>
<style lang="scss" scoped>
/deep/.van-tabbar {
  border-top: 1px solid var(--tab-bar-top-border-color);
}

/deep/ .van-tabbar-item__icon img {
  height: .65rem;
}
</style>
